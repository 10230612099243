


























import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ExitCondition from '@/components/organisms/v3/mode/DefaultExitCondition.vue'
import SettingItem from '@/components/molecules/v3/SettingItem.vue'
import LevelDifficult, { DifficultTableItem } from '@/components/organisms/v3/mode/LevelDifficult.vue'
import LessonProgressSetting from '@/components/organisms/v3/mode/LessonProccessSettingModeDefault.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

type DIFFICULT_TYPES = 'RK' | 'EN'

const CLASS_MODE_CODE_AI = 'AI'
@Component({
  components: {
    ExitCondition,
    SettingItem,
    TitleBase,
    TitleTextBase,
    LessonProgressSetting,
    LevelDifficult,
  },
})
export default class ModeDefault extends Vue {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: '通常学習', active: true },
  ]
  private showPopupLearnEndCondition = false
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private difficultInitial = null

  private async getListDifficult() {
    try {
      const response = await Vue.prototype.$http.httpWithToken.get('/v3/class_mode_difficulty_settings', {
        params: { class_mode_code: CLASS_MODE_CODE_AI, branch_id: this.branchId },
      })
      this.difficultInitial = response.data.class_mode_difficulty_settings
    } catch (error) {
      alert('Something went wrong!')
    }
  }

  private async handleSubmit(
    difficultType: DIFFICULT_TYPES,
    difficultData: { RK?: DifficultTableItem[]; EN?: DifficultTableItem[] }
  ) {
    const difficultBody = {
      branch_id: this.branchId,
      class_mode_code: CLASS_MODE_CODE_AI,
      class_category_code: difficultType,
      subject_levels: difficultData[difficultType]?.map((difficultItem: DifficultTableItem) => {
        return {
          subject_id: difficultItem.subjectId,
          levels: [1, 2, 3].filter((item) => difficultItem['difficult' + item].checked)
        }
      })
    }
    try {
      Vue.prototype.$loading.start()
      await Vue.prototype.$http.httpWithToken.post('/v3/class_mode_difficulty_settings', difficultBody)
      await this.getListDifficult()
      alert('難易度を設定しました')
    } catch (error) {
      alert('Something went wrong!')
    } finally {
      Vue.prototype.$loading.complete()
    }
  }

  private mounted() {
    Vue.prototype.$loading.start()
    this.getListDifficult()
    Vue.prototype.$loading.complete()
  }


}
