

























import { Component, Vue, Ref, Prop } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalDefaultExitCondition from '@/components/organisms/v3/mode/ModalConditionExit.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import IconLoading from '@/components/atoms/v3/IconLoading.vue'

@Component({
  components: {
    ColoredBox,
    TableBase,
    ModalDefaultExitCondition,
    PopupBase,
    ButtonBase,
    IconLoading,
  },
})
export default class DefaultExitCondition extends Vue {
  @Prop()
  show?: boolean

  @Prop()
  handleClose?: () => void

  private tableBasePerPage = null

  private isLoading = false

  private tableBaseChallengeItems = []

  private tableBaseFields = [
    { key: 'subject', label: '教科' },
    { key: 'item', label: '制限項目' },
    { key: 'limit', label: '制限しきい値' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons = ['action']

  private itemOptionDatas: { value?: number; text?: string; unit?: string }[] = []

  @Ref()
  modalCondition!: ModalDefaultExitCondition

  private showDefaultExitCondition(number: number, index: number): void {
    const item: any = this.tableBaseChallengeItems[index]
    this.modalCondition.setDefaultExitCondition(item.id, item.subject, item.itemId, item.threshold)
    this.modalCondition.show()
  }

  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private async mounted() {
    await this.loadDatas()
    await this.loadRestrictions()
  }

  private async submitData() {
    this.isLoading = true
    await Promise.all(
      this.tableBaseChallengeItems.map((item: any) => this.update(item.id, item.itemId, item.threshold))
    )
      .then(() => {
        alert('設定内容が保存されました。')
        this.closeEvent()
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
      this.isLoading = false
  }

  private async update(id: number, restrictionId: number, threshold: number) {
    await Vue.prototype.$http.httpWithToken.put(`/branch_end_conditions/${id}`, { restrictionId, threshold })
  }

  private closeEvent() {
    this.$emit('handle-close')
  }

  private updateDatas(data: {
    subjectId: number
    restrictionId: number
    limitThreshold: number
    unit: string
    restrictionName: string
  }) {
    const endConditions = [...this.tableBaseChallengeItems]
    const _data: any = endConditions.find((item: any) => item.id == data.subjectId) || {}
    _data.threshold = data.limitThreshold
    _data.limit = data.limitThreshold + data.unit
    _data.item = data.restrictionName
    _data.itemId = data.restrictionId
    _data.unit = data.unit
  }

  private async loadRestrictions() {
    await Vue.prototype.$http.httpWithToken.get('/restrictions').then((res: any) => {
      this.itemOptionDatas =
        res.data.restrictions.map((restriction: { id: number; name: string; unit: string }) => {
          return {
            value: restriction.id,
            text: restriction.name,
            unit: restriction.unit,
          }
        }) || []
    })
  }

  public async loadDatas() {
    await Vue.prototype.$http.httpWithToken.get(`/branch_end_conditions?branchId=${this.branchId}`).then((res: any) => {
      const categories = res.data.reduce(
        (accumulator: any, category: { branchEndConditions: any; classCategoryName: any }) => {
          accumulator[category.classCategoryName] = category.branchEndConditions.map(
            (endCondition: {
              id: number
              name: string
              subjectName: string
              threshold: string
              restrictionName: string
              restrictionId: number
              restrictionUnit: string
            }) => {
              return {
                id: endCondition.id,
                subject: endCondition.subjectName,
                item: endCondition.restrictionName,
                limit: endCondition.threshold + endCondition.restrictionUnit,
                action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
                itemId: endCondition.restrictionId,
                threshold: endCondition.threshold,
              }
            }
          )
          return accumulator
        },
        {}
      )
      this.tableBaseChallengeItems = categories['演習']
    })
  }
}
