
























import { Component, Vue, Mixins, Prop, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    ModalBase,
  },
})
export default class ModalConditionExit extends Mixins(ModalBaseMethod) {
  private selectedSubject = ''
  @Prop()
  private itemOptionDatas!: {
    value: string | number
    text: string
    unit: string
  }[]

  private itemSelectedData = 0

  private itemLimit: string | number = 0

  private id = 0

  public async setDefaultExitCondition(id: number, subject: string, restrictionId: number, threshold: number) {
    this.itemSelectedData = restrictionId
    this.itemLimit = threshold
    this.selectedSubject = subject
    this.id = id
  }

  get setUnit(): string {
    const itemOption: any = this.itemOptionDatas.find((item) => {
      return this.itemSelectedData === item.value
    })
    return itemOption ? itemOption.unit : ''
  }

  @Watch('itemLimit')
  private watchInputNumber(newValue: number, oldValue: number) {
    const check = new RegExp(/^\d+$/)
    if (
      newValue + '' === '' ||
      (check.test(newValue + '') &&
        ((this.itemSelectedData == 3 && (newValue <= 100 || newValue < oldValue)) || this.itemSelectedData != 3))
    ) {
      this.itemLimit = newValue
    } else {
      this.itemLimit = oldValue
    }
  }

  /**
   * ボタンの色（ボタン操作制御）
   */
  private get colortype(): string {
    const permitted =
      this.itemLimit &&
      this.itemLimit > 0 &&
      ((this.itemSelectedData == 3 && this.itemLimit <= 100) || this.itemSelectedData != 3)

    return permitted ? 'blue' : 'pointer-events-none'
  }

  private update() {
    const restriction = this.itemOptionDatas.find((item: any) => item.value == this.itemSelectedData)
    const data = {
      subjectId: this.id,
      restrictionId: this.itemSelectedData,
      limitThreshold: this.itemLimit,
      unit: restriction?.unit || '',
      restrictionName: restriction?.text || '',
    }
    this.hide()
    this.$emit('updateDatas', data)
  }
}
