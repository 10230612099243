









import ColoredBox from '@/components/atoms/ColoredBox.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ColoredBox,
  },
})
export default class School extends Vue {
  @Prop()
  label?: string

  @Prop()
  hidden?: boolean

  @Prop()
  height?: number

  @Prop()
  style?: string

  private get fillHeight() {
    return this.height ? { height: this.height + 'px' } : {}
  }
}
