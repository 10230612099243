























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import _ from 'lodash'
import { ClassPolycies, ModeType } from '@/types/teacher/mode'
import { ServiceCodeType } from '@/types/teacher/service'

@Component({
  components: {
    ButtonBase,
    RadioToggle,
    PopupBase,
  },
})
export default class LessonProgressSettingModeDefault extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private isShowModal = false
  private items: ClassPolycies[] = []
  private itemsOrigin: ClassPolycies[] = []

  private showVideoLearningFlow = false
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId
  private get colorType() {
    return this.items.some((item) => item.isEnabled) ? 'blue' : 'pointer-events-none'
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    await this.handleVideoService()
    Vue.prototype.$loading.complete()
  }

  private openPopup() {
    this.itemsOrigin = _.cloneDeep(this.items)
    this.isShowModal = true
  }

  private async loadDatas() {
    // 授業方針情報を取得
    await Vue.prototype.$http.httpWithToken
      .get(`/classPolicies`, {
        params: {
          branchId: this.branchId,
          isGroup: false,
        },
      })
      .then((res: any) => {
        this.items = res.data.filter((classInfo: ClassPolycies) => classInfo.classCategoryName !== '確認テスト')
      })
  }

  private async handleVideoService() {
    const params = { branchId: this.branchId, academyId: this.academyId }
    const response = await Vue.prototype.$http.httpWithToken.get(`/v3/branches/services`, { params: params })

    const branchService = response.data.branches[0]?.services
    if (branchService) {
      const videoServiceCode = 'gakken'
      const videoService = branchService.find((service: { code: ServiceCodeType }) => service.code === videoServiceCode)
      this.showVideoLearningFlow = videoService.subjects.some(
        (videoServiceOfSubject: { isEnabled: boolean }) => videoServiceOfSubject.isEnabled
      )
    }
  }

  private async handleSubmit() {
    this.isShowModal = false
    const classPolycies = []
    for (let i = 0; i < this.items.length; i++) {
      classPolycies.push({
        id: this.items[i].id,
        sortNum: i + 1,
        isEnabled: this.items[i].isEnabled,
      })
    }
    await Vue.prototype.$http.httpWithToken
      .patch(`/classPolicies`, {
        branchId: this.branchId,
        isGroup: false,
        classPolicies: classPolycies,
      })
      .then(() => {
        alert('更新しました。')
      })
      .catch((error: any) => {
        this.items = _.cloneDeep(this.itemsOrigin)
        if (error.response.data.status === 422) {
          alert('全項目をなしにすることは出来ません。')
        } else {
          alert('エラーが発生しました。操作をやり直してください。')
        }
      })
  }

  private handleCancel() {
    this.isShowModal = false
    this.items = _.cloneDeep(this.itemsOrigin)
  }

  get ItemChecked() {
    return this.items
      .filter((item) => item.isEnabled && (item.classCategoryName !== '導入動画' || this.showVideoLearningFlow))
      .map((item) => item.classCategoryName)
      .join('→')
  }
}
